import mapImages from '@/util/mapImages'
import Util from '@/util'
// import ApiService from '@/util/api.service'
import IncidentMovement from '@/assets/map/incident_movement.svg'
import IncidentMovementEnd from '@/assets/map/incident_movement_end.svg'
import RapidSOS from '@/assets/map/rapidsos.png'
import BaseStore from './baseStore'

const ol = window.ol

class IncidentStore extends BaseStore {
  constructor(map, source) {
    super(map, source)
    this.visibleCondition = { status: ['ACTIVO', 'HISTORICO'] }
    this.hoveredId = null
    this.sosIncidents = new Map()

    this.categoryCount = {
      seguridad: 0,
      medico: 0,
      proteccion_civil: 0,
      servicios_publicos: 0
    }
    this.visibleConditionRules = {
      groupId: 'LIST',
      status: 'LIST',
      category: 'LIST',
      uniqueId: 'STRING',
      disabled: 'BOOLEAN'
    }
  }

  _createMarker(item) {
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item.longitude + Math.random() * 0.000001, item.latitude + Math.random() * 0.000001
      ])),
      name: item.id,
      population: 4000,
      rainfall: 500
    });
    item.typeOfItem = 'incidents'
    if (this.sosIncidents.has(item.uniqueId)) {
      item.typeOfItem = 'sosIncident'
      item.sosData = this.sosIncidents.get(item.uniqueId)
    }
    this.categoryCount[item.category]++
    marker.setId('incidents__' + item.id)
    marker.set('record', item)
    this.markers.set(item.id, marker)
    this._updateMarker(item)
    return marker
  }

  _updateMarker(item) {
    const tmpMarker = this.markers.get(item.id)
    item.statusTitle = Util.statusMap[item.status]
    tmpMarker.setStyle(this._getStyle(item))
    const record = tmpMarker.get('record')
    if (item.latitude !== record.latitude || item.longitude !== record.longitude) {
      tmpMarker.getGeometry().setCoordinates(window.ol.proj.fromLonLat([
        item.longitude, item.latitude
      ]))
    }
    const visible = this._isVisible(item)
    Object.assign(record, item)
    this.notify(item)
    this._updateVisbility(tmpMarker, visible)
  }

  updateMarker(item) {
    if (!this.markers.get(item.id)) {
      return this._createMarker(item)
    }
    const record = this.markers.get(item.id).get('record')
    item.typeOfItem = record.typeOfItem
    if (item.status === 'HISTORICO') {
      if (this.map.trip) {
        // this.map.trip.emit('unsubscribecad', item.uniqueId)
      }
      this.categoryCount[item.category]--
    }
    this._updateMarker(item)
    return null
  }

  _getStyle(item) {
    if (item.typeOfItem === 'sosIncident') {
      return this._getIcon(mapImages.incident.sos, item.name, {
        offsetY: -35,
        offsetX: 1
      })
    }
    const category = mapImages.incident
    let color = '#fff'
    if (item?.attributes?.prioridad) {
      switch (item?.attributes?.prioridad) {
        case 'ALTA':
          color = '#ff0000'
          break;
        case 'MEDIA':
          color = '#ff8000'
          break;
        case 'BAJA':
          color = '#ffff00'
          break;
        default:
          break;
      }
    }
    return this._getIcon(category[item.category] || category.seguridad, item.name, {
      scale: category.scale,
      offsetY: category.offsetY,
      offsetX: category.offsetX
    }, color)
  }

  // _getStyle(item) {
  //   if (item.typeOfItem === 'sosIncident') {
  //     return this._getIcon(mapImages.incident.sos, item.name, {
  //       offsetY: -35,
  //       offsetX: 1
  //     })
  //   }
  //   if (item.rapidSosLocations && item.rapidSosLocations.length) {
  //     return this._getSosIcon({})
  //   }
  //   const category = mapImages.incident.seguridad
  //   let icon = null
  //   let scale = 0.6
  //   if (item.category && item.categoryData) {
  //     icon = item.categoryData.image
  //   } else {
  //     console.error(`Categoría de dispositivo no definido "${item.category}"`)
  //   }
  //   if (item.selected || this.hoveredId === item.id) {
  //     scale = 0.7
  //   }
  //   if (icon) {
  //     icon = (process.env.VUE_APP_IMAGES_PATH || '/api/v2/public/images/') + (icon.split('/').pop())
  //   } else {
  //     icon = category.up
  //     scale = 1
  //   }
  //   return this._getIcon(icon, item.name, {
  //     // rotation: item.position ? item.position.course : 0,
  //     scale,
  //     offsetY: category.offsetY,
  //     offsetX: category.offsetX
  //   })
  // }

  _getStyleSelected(item) {
    return this._getStyle(item)
  }

  _getSosIcon({ anchor = [6, 5], scale = 0.7 }) {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor,
        anchorXUnits: 'pixels',
        anchorYUnits: 'pixels',
        src: RapidSOS,
        scale,
      }),
      zIndex: 20
    })
  }

  async updateMarkersStyle() {
    this.markers.forEach(async (marker) => {
      marker.setStyle(this._getStyle(marker.get('record')))
    })
  }

  hoverMarker(id) {
    if (!id && this.hoveredId) {
      const marker = this.markers.get(this.hoveredId)
      this.hoveredId = null
      marker.setStyle(this._getStyle(marker.get('record')))
    } else if (id !== this.hoveredId) {
      this.hoveredId = id
      const marker = this.markers.get(id)
      marker.setStyle(this._getStyleSelected(marker.get('record')));
    }
  }

  async setAdditionalData(item) {
    if (item?.cadId) {
      this.markers.forEach((marker) => {
        const record = marker.get('record')
        if (record.uniqueId === item.cadId) {
          switch (item.systemName) {
            case 'RAPID-SOS':
              item.typeOfItem = 'reportant';
              record.rapidSosLocations = item.persons?.[0]?.positions
              break;
            case 'ONSTAR':
              item.typeOfItem = 'onstar';
              break;
            case 'SIS-MUJERES':
              item.typeOfItem = 'sismujeres';
              record.sisPersons = item.persons
              break;
            default:
              break;
          }
          this._updateMarker(record)
        }
      })
    }
  }

  async getMovement(incident) {
    if (this.map.trip) {
      // this.map.trip.emit('subscribecad', incident.uniqueId);
    }
  }

  updateMovementLayerVisibility(visible) {
    window.VMA.mapComponent.incidentRouteLayer.setVisible(visible)
  }

  writeMovementLine(incident, data, idd) {
    const points = [...[{
      latitude: incident.latitude,
      longitude: incident.longitude,
    }], ...data].map((item) => {
      return {
        ...incident,
        _movementData: item,
        _point: ol.proj.transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
      }
    })
    const featureLine = new ol.Feature({
      geometry: new ol.geom.LineString(points.map((p) => p._point))
    })
    featureLine.setId(idd + incident.id)
    featureLine.setStyle(new ol.style.Style({
      stroke: new ol.style.Stroke({
        color: '#14a3c7',
        width: 5
      }),
      zIndex: 10
    }))
    points.shift()
    points.pop()
    const circles = points.map((item, i) => {
      switch (idd) {
        case 'rapid-sos':
          item.typeOfItem = 'incidentMovement'
          break;
        case 'sis-mujeres1':
        case 'sis-mujeres2':
          item.typeOfItem = 'sismujeres'
          break;
        default:
          break;
      }
      const circleFeature = new ol.Feature({
        geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
          item._movementData.longitude, item._movementData.latitude
        ])),
        name: item.id,
        population: 4000,
        rainfall: 500
      });
      circleFeature.setId('coordinate_selected' + idd + i)
      circleFeature.set('record', item)
      circleFeature.setStyle(new ol.style.Style({
        image: new ol.style.Icon({
          anchor: [0.5, 0.9],
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: IncidentMovement,
          // scale: options.scale,
        }),
        zIndex: 20
      }))
      return circleFeature
    })
    const lastPosition = data.slice(-1)[0]
    const item = {
      ...incident,
      typeOfItem: 'incidentMovement',
      _movementData: lastPosition
    }
    switch (idd) {
      case 'rapid-sos':
        item.typeOfItem = 'incidentMovement'
        break;
      case 'sis-mujeres1':
      case 'sis-mujeres2':
        item.typeOfItem = 'sismujeres'
        break;
      default:
        break;
    }
    const marker = new ol.Feature({
      geometry: new ol.geom.Point(window.ol.proj.fromLonLat([
        item._movementData.longitude, item._movementData.latitude
      ])),
      name: item.id,
      population: 4000,
      rainfall: 500
    });
    marker.setStyle(new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.9],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: IncidentMovementEnd,
        // scale: options.scale,
      }),
      zIndex: 20
    }))
    marker.set('record', item)
    marker.setId('incidents_seguimiento__' + idd + item.id)
    this.movementSource.addFeature(featureLine);
    this.movementSource.addFeatures(circles)
    this.movementSource.addFeature(marker);
  }

  writeMovement(incident) {
    this.movementSource.clear();
    if (incident?.rapidSosLocations?.length) {
      this.writeMovementLine(incident, incident.rapidSosLocations, 'rapid-sos')
    }
    if (incident?.sisPersons?.length && incident?.sisPersons[0]?.positions) {
      this.writeMovementLine(incident, incident.sisPersons[0].positions, 'sis-mujeres1')
    }
    if (incident?.sisPersons?.length && incident?.sisPersons[1]?.positions) {
      this.writeMovementLine(incident, incident.sisPersons[1].positions, 'sis-mujeres2')
    }
  }
}
export default IncidentStore
