const ol = window.ol

const BaseStore = class {
  constructor(map, source) {
    this.map = map
    this.markers = new Map()
    this.visibleMarkers = new Map()
    this.olSource = source
    this.visibleCondition = { }
    this.visibleItems = []
    this.visibleConditionRules = { }
    this.handlers = [];
  }

  subscribe(fn) {
    this.handlers.push(fn);
  }

  notify(item) {
    this.handlers.forEach((fn) => {
      fn(item)
    });
  }

  // eslint-disable-next-line
  _sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  _updateVisbility(marker, visible) {
    const item = marker.get('record')
    if (visible && !this.visibleMarkers.has(item.id)) {
      this.visibleMarkers.set(item.id, marker)
      this.olSource.addFeature(marker)
      if (this.visibleItems.indexOf(item) === -1) {
        this.visibleItems.unshift(item)
      }
    }
    if (!visible && this.visibleMarkers.has(item.id)) {
      this.visibleMarkers.delete(item.id)
      this.olSource.removeFeature(marker)
      if (this.visibleItems.indexOf(item) > -1) {
        const index = this.visibleItems.indexOf(item);
        this.visibleItems.splice(index, 1)
      }
    }
  }

  _updateFilter() {
    this.markers.forEach(marker => {
      const item = marker.get('record')
      const visible = this._isVisible(item)
      this._updateVisbility(marker, visible)
      if (item.selected && this.updateMovementLayerVisibility) {
        this.updateMovementLayerVisibility(visible)
      }
    });
  }

  updateFilter(filter) {
    if (!filter) return
    const changes = Object.keys(filter).reduce((key, acc) => {
      if (acc || JSON.strinfy(filter[key]) === JSON.strinfy(this.visibleCondition)) {
        return true
      }
      return false
    }, false)
    if (changes) {
      this.visibleCondition = { ...this.visibleCondition, ...filter }
      this._updateFilter()
    }
  }

  clearFilter() {
    this.visibleCondition = {}
    this._updateFilter()
  }
  // eslint-disable-next-line
  _checkList(value, control) {
    let result = false || !Array.isArray(control)
    if (Array.isArray(control) && control.length) {
      control.forEach(element => {
        result = result || element === value
      });
    }
    return result
  }

  // eslint-disable-next-line
  _checkString(value, control){
    if (typeof control === 'string' || control instanceof String) {
      return value.includes(control)
    }
    return false
  }

  // eslint-disable-next-line
  _checkBoolean(value, control){
    if (typeof control === 'boolean' || control instanceof Boolean) {
      return control === value
    }
    return false
  }

  _isVisible(item) {
    const properties = Object.keys(this.visibleConditionRules)
    let result = true
    properties.forEach(property => {
      if (this.visibleCondition[property]) {
        switch (this.visibleConditionRules[property]) {
          case 'LIST':
            result = result && this._checkList(item[property], this.visibleCondition[property])
            break;
          case 'STRING':
            result = result && this._checkString(item[property], this.visibleCondition[property])
            break;
          case 'BOOLEAN':
            result = result && this._checkBoolean(item[property], this.visibleCondition[property])
            break;
          default:
            result = false
        }
      }
    });
    return result
  }

  isMakerOnDisplay(marker) {
    const displayArea = this.map.mapView.calculateExtent()
    const coord = marker.getGeometry().flatCoordinates
    return coord[0] >= displayArea[0] && coord[0] <= displayArea[2] && coord[1] >= displayArea[1] && coord[1] <= displayArea[3]
  }

  // eslint-disable-next-line
  _getIcon(image, label, options = {}, textColor='#fff') {
    return new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 0.5],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        src: image,
        scale: options.scale,
        rotation: options.rotation
      }),
      text: new ol.style.Text({
        text: label,
        scale: 1,
        fill: new ol.style.Fill({
          color: textColor
        }),
        offsetY: options.offsetY || -25,
        offsetX: options.offsetX || -10,
        stroke: new ol.style.Stroke({
          color: '0',
          width: 3
        })
      })
    })
  }

  getRecord(id) {
    const marker = this.markers.get(id)
    if (!marker) {
      return undefined
    }
    return marker.get('record')
  }

  getRecords() {
    const items = []
    this.markers.forEach((marker) => {
      items.push(marker.get('record'))
    })
    return items
  }

  center(id, zoom = 17, longField = 'longitude', latField = 'latitude') {
    if (this.markers.has(id)) {
      const item = this.markers.get(id).get('record')
      this.map.mapView.setCenter(window.ol.proj.fromLonLat([
        item[longField], item[latField]
      ]))
      this.map.mapView.setZoom(zoom)
    }
  }

  clear() {
    this.visibleMarkers.clear()
    this.markers.clear()
    this.visibleItems = []
    this.olSource.clear()
  }
}

export default BaseStore
