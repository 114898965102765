<template>
  <div v-if="!loading">
    <v-list class="pa-0" two-line v-if="filteredItems.length">
      <div>
        <div v-for="(item, index) in paged" :key="'item' + index">
          <v-list-item :class="item.disabled ? 'grey lighten-3': item.locked === false? 'red ligthen-3':''">
            <v-list-item-avatar :color="item.avatarColor" v-if="item.avatarColor">
            </v-list-item-avatar>
            <v-list-item-avatar v-if="typeof item.avatarProgress === 'number'">
              <v-progress-circular
                :rotate="-90"
                :size="40"
                :width="3"
                :value="item.avatarProgress"
                color="info"
              >
                {{ item.avatarProgress }}%
              </v-progress-circular>
            </v-list-item-avatar>
            <!-- <v-list-item-icon v-if="item.show_icon">
              <v-icon :color="item.color || 'primary'" large>
                {{ item.icon || 'mdi-checkbox-blank-circle-outline' }}
              </v-icon>
            </v-list-item-icon> -->
            <v-list-item-content style="padding-bottom: unset !important;">
              <v-list-item-title v-if="getTitle" v-html="getTitle(item)"></v-list-item-title>
              <v-list-item-subtitle v-if="getSubTitle" v-html="getSubTitle(item)">
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="getExtra" v-html="getExtra(item)"></v-list-item-subtitle>
            </v-list-item-content>
            <actions-button :item="item" :actions="actions" :itemIndex="index">
            </actions-button>
          </v-list-item>
          <v-divider :key="index" :inset="false"></v-divider>
        </div>
      </div>
      <div class="list-pagination">
        <v-pagination
          v-model="page"
          :length="pages"
          :total-visible="7"
        ></v-pagination>
      </div>
    </v-list>
    <v-container v-else class="no-results">
      <div align="center" justify="center">
        <v-row align="center" justify="center">
          <p class="text-center">
            <v-icon size="150" class="nonono">
              mdi-playlist-remove
            </v-icon>
          </p>
        </v-row>
        <v-row align="center" justify="center">
          <p class="text-center nonono" style="font-size: 1.5rem;">
            {{ empty_message }}
          </p>
        </v-row>
      </div>
    </v-container>
  </div>
  <div v-else>
    <v-container class="pa-0" fluid>
      <v-skeleton-loader
        ref="skeleton"
        type="list-item-avatar-three-line"
        :boilerplate="false"
        :tile="false"
        class="mx-auto"
        v-for="skeleton in skeletons"
        :key="skeleton"
      ></v-skeleton-loader>
    </v-container>
  </div>
</template>
<script>
import Actions from './Actions.vue'

export default {
  props: {
    items: Array,
    actions: Array,
    empty_message: {
      type: String,
      default: 'No se encontraron resultados'
    },
    loading: {
      default: false
    },
    getTitle: Function,
    getSubTitle: Function,
    getExtra: Function,
    search: String
  },
  components: {
    'actions-button': Actions
  },
  data() {
    return {
      page: 1,
      perPage: 10,
      loaders: 10
    }
  },
  computed: {
    filteredItems() {
      if (!this.search) return this.items
      return this.items.filter(
        (item) => {
          const text = `${this.getTitle(item)} ${this.getSubTitle(item)} ${this.getExtra(item)}`;
          return text.toSlug().indexOf(this.search.toSlug()) > -1
        }
      )
    },
    paged() {
      const from = (this.page - 1) * this.perPage
      return this.filteredItems.slice(from, from + this.perPage)
    },
    pages() {
      return Math.ceil(this.filteredItems.length / this.perPage)
    },
    skeletons() {
      const skeletons = [];
      for (let i = 1; i <= this.loaders; i++) {
        skeletons.push(i);
      }
      return skeletons;
    },
  }
}
</script>
<style type="text/css" scoped>
  .child-right {
    background: green;
    height: 100%;
    position: absolute;
    top: 50;
    left: 0;
    right: 0;
  }
  .v-chip.v-size--default {
    border-radius: 16px;
    font-size: 12px;
    height: unset !important;
  }
  .v-list-item__action {
    margin-right: unset !important;
  }
  .v-chip {
    margin-right: 5px;
  }
  .v-list-item__subtitle {
    margin-bottom: 3px;
    user-select: text;
    white-space: unset !important;
  }
  .v-list-item__title {
    user-select: text;
  }
  .v-list-item__subtitle .v-chip {
    user-select: none;
  }
  .v-input__slot {
    margin-bottom: unset !important;
  }
  .v-text-field--solo {
    border-radius: unset;
    margin-bottom: unset !important;
    box-shadow:
      0 1px 1px -2px rgba(0,0,0,.1),
      0 1px 1px 0 rgba(0,0,0,.14),
      0 1px 1px 0 rgba(0,0,0,.12) !important;
  }

  .no-results .nonono {
    color: rgba(0,0,0,.20) !important;
  }

  .list-pagination {
    position: fixed;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .gris {
    background-color:lightgray;
  }
</style>
