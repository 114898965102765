<template>
  <v-card class="mx-auto elevation-0" height="100%">
    <v-toolbar class="kalan-titles elevation-0" color="primary--text">
      <v-toolbar-title>
        <span>{{ $vuetify.lang.t(`$vuetify.${model}.plural_name`) }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="margin-right: 20px">
        <v-text-field
          flat
          label="Buscar"
          hide-details
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <actions-button :item="{}" :actions="actions"></actions-button>
    </v-toolbar>
    <kalan-list
      :loading="loading"
      :getTitle="getTitle"
      :getSubTitle="getSubTitle"
      :getExtra="getExtra"
      :items="items"
      :search="search"
      :actions="listActions"
    >
    </kalan-list>
  </v-card>
</template>

<script>
import ApiService from '@/util/api.service';
import Profiles from '@/models/profiles.model';
import Crud from '@/util/crud.service';
import ProfilesService from '@/util/profiles.service'

export default {
  props: {
    model: {
      default: 'profiles',
    },
  },
  components: {
    'kalan-list': () => import('@/components/modulo/List.vue'),
    'actions-button': () => import('@/components/modulo/Actions.vue'),
  },
  data() {
    return {
      items: [],
      loading: true,
      search: '',
      actions: [
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.create`),
          icon: 'mdi-plus',
          color: 'secondary',
          action: (item) => {
            Crud.create({
              item,
              schema: Profiles,
              model: this.model,
              url: '/v2',
            }).then(this.load);
          },
        },
      ],
      listActions: [
        {
          help: 'Permisos',
          icon: 'mdi-key',
          color: 'yellow',
          action: (item) => {
            ProfilesService.groups({
              profile: item,
            });
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.update`),
          icon: 'mdi-pencil',
          color: 'accent',
          action: (item) => {
            Crud.update({
              item,
              schema: Profiles,
              model: this.model,
              url: '/v2',
              title: this.getTitle(item),
            }).then(this.load);
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.read`),
          icon: 'mdi-eye',
          color: 'mattBlack',
          action: (item) => {
            Crud.read({
              item,
              schema: Profiles,
              model: this.model,
              url: '/v2',
              title: this.getTitle(item),
            });
          },
        },
        {
          help: window.VMA.$vuetify.lang.t(`$vuetify.${this.model}.delete`),
          icon: 'mdi-delete',
          color: 'red',
          action: (item) => {
            Crud.delete({
              id: item.id,
              model: this.model,
              url: '/v2',
              title: this.getTitle(item),
            }).then(this.load);
          },
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    getTitle: (item) => item.name,
    getSubTitle: (item) => item.phone,
    getExtra: () => null,
    load() {
      this.loading = true;
      ApiService({
        url: `/v2/${this.model}`,
        method: 'get',
      })
        .then((resp) => {
          this.items = resp;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>
