export default {
  _id: {
    type: String,
    visibility: false,
  },
  name: {
    label: 'Nombre',
    type: String,
  },
};
