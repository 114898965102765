<template>
  <div class="flat-corner" style="pointer-events: auto;">
    <div
      class="card-flat-corner pattern-cross-dots-md"
    >
      <div class="title-flat-corner" :style="{ height: titleHeight }">
        <div class="title-card-flat-corner">
          <div class="d-flex text-center justify-space-between align-center fill-height">
            <div class="title-text ml-2">{{title}}</div>
            <div class="mr-3">
              <v-tooltip
                v-for="(action, actionIndex) in actions"
                :key="actionIndex"
                top
              >
                <template v-slot:activator='{ on }'>
                  <v-icon
                    size="18"
                    dark
                    class="cursor-pointer"
                    :class="{'mr-2': actionIndex !== actions.length - 1}"
                    v-on="on"
                    @click="action.action(action)"
                  >
                {{action.icon}}
              </v-icon>
                </template>
                <span>{{action.help}}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div :style="{
        height: 'calc(100% - ' + titleHeight + ')'
      }">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    actions: Array,
    titleHeight: {
      type: String,
      default: '25px'
    }
  }
}
</script>

<style lang="css" scoped>
.cursor-pointer {
  cursor: pointer;
}

.flat-corner {
  display: block;
  position: relative;
  border-radius: 7px 0px 0px 0px;
  width: 100%;
  height: 100%;
  background-color: rgb(246 244 244 / 87%);
  box-sizing: border-box;
  clip-path: polygon(
    0% 0%,
    calc(100% - 15px) 0%,
    calc(100% - 7.5px) 7.5px,
    100% 15px,
    100% 100%,0% 100%,0% 63%,0% 29%);
}

.card-flat-corner {
  color: rgb(105, 103, 103);
  background-color: #1b222d;
  background-repeat: repeat;
  padding: 3px;
  position: absolute;
  border-radius: 7px 0px 0px 0px !important;
  top: 2px; /* equal to border thickness */
  left: 2px; /* equal to border thickness */
  width: calc(100% - 4px); /* container height - (border thickness * 2) */
  height: calc(100% - 4px); /* container height - (border thickness * 2) */
  clip-path: polygon(
    0% 0%,
    calc(100% - 15px) 0%,
    calc(100% - 7.5px) 7.5px,
    100% 15px,
    100% 100%,0% 100%,0% 63%,0% 29%);
}

.title-text {
  font-weight: 900;
  letter-spacing: 4px;
  color: white;
  text-transform: uppercase;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.title-flat-corner {
  color: white;
  display: block;
  position: relative;
  border-radius: 5px 0px 0px 0px;
  width: 100%;
  background-color: rgb(175, 177, 189) !important;
  opacity: 0.9;
  box-sizing: border-box;
  clip-path: polygon(
    0% 0%,
    calc(100% - 15px) 0%,
    calc(100% - 7.5px) 7px,
    100% 14px,
    100% 100%,0% 100%,0% 63%,0% 29%);
}

.title-card-flat-corner {
  position: absolute;
  background: #0000006e;
  border-radius: 5px 0px 0px 0px !important;
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  clip-path: polygon(
    0% 0%,
    calc(100% - 15px) 0%,
    calc(100% - 7.5px) 7px,
    100% 14px,
    100% 100%,0% 100%,0% 63%,0% 29%);
}
</style>
