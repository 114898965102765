<template>
  <div v-show="show" class="inputGroup">
    <input :id="'chk-' + name" type="checkbox" v-model="value" :disabled="readonly || loading" />
    <label :for="'chk-' + name">{{ label }}</label>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </div>
  <!-- <v-switch
  v-model="value"
  v-show="show"
  :label="label"
  :disabled="readonly"
  :error="error"
  :error-messages="message"
  autocomplete="off"
  /> -->
</template>

<script>
export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: Boolean,
    readonly: Boolean,
    optional: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    titles: {
      type: Object,
      default: () => ({
        true: 'Si',
        false: 'No',
      }),
    },
    visibility: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.value = this.default;
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true,
      loading: false,
    };
  },
  methods: {
    setValueWithoutTriggers(value) {
      this.firstLoad = true
      this.value = value
    },
    select() {
      if (!this.readonly) {
        this.value = !this.value;
      }
    },
    getItem() {
      return { name: this.name, value: this.value };
    },
    removeError() {
      this.error = false;
      this.message = null;
    },
    setError(message) {
      this.error = true;
      this.message = message;
    },
    setVisibility(val) {
      this.show = val || false;
    },
  },
  computed: {
    inputColor() {
      return this.readonly ? 'grey' : '#4f7dd1';
    },
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val);
      } else {
        this.firstLoad = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inputGroup {
  --asd-color: v-bind(inputColor);
  background-color: #fff;
  display: block;
  margin: 10px 0;
  position: relative;

  label {
    padding: 12px 30px;
    width: 100%;
    display: block;
    text-align: left;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition: color 200ms ease-in;
    overflow: hidden;

    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: #f9fbfc;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }

    &:after {
      width: 32px;
      height: 32px;
      content: "";
      border: 2px solid #d1d7dc;
      background-color: #fff;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
      background-repeat: no-repeat;
      background-position: 2px 3px;
      border-radius: 50%;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 200ms ease-in;
    }
  }

  input:checked ~ label {
    color: #000;

    &:before {
      transform: translate(-50%, -50%) scale3d(62, 62, 1);
      opacity: 1;
    }

    &:after {
      background-color: var(--asd-color);
      border-color: var(--asd-color);
    }
  }

  input {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}
</style>
