import Dialog from '@/components/dialog'
import Form from '@/components/form'
import ApiService from '@/util/api.service'

const transformRequest = (data, headers, schema) => {
  let files = false
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof File) {
      files = true
    }
  })
  if (files) {
    headers['Content-Type'] = 'multipart/form-data';
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return formData
  }
  const jsonData = JSON.stringify(data)
  return jsonData;
}

const create = ({
  schema, item = {}, model, preSave, url
}) => {
  const params = {
    dialogTitle: window.VMA.$vuetify.lang.t(`$vuetify.${model}.create`),
    url: `${url || ''}/${model}`,
    method: 'post'
  }
  return new Promise((resolve, reject) => {
    const form = new Form({
      schema,
      item
    })
    const dialog = new Dialog({
      title: params.dialogTitle,
      actions: [{
        help: 'Guardar',
        icon: 'mdi-content-save',
        color: 'secondary',
        action: async () => {
          if (form.hasErrors()) {
            return
          }
          window.VMA.loading(true)
          let _item = form.getItem()
          if (preSave) {
            _item = await preSave(_item)
          }
          ApiService({
            url: params.url,
            method: params.method,
            data: _item,
            transformRequest: [transformRequest]
          }).then((resp) => {
            dialog.close()
            window.VMA.showMessage({
              title: `${window.VMA.$vuetify.lang.t(`$vuetify.${model}.name`)} creado`,
              // body: 'Uber Activado',
              color: 'success',
              center: false,
              right: true
            });
            resolve(resp)
          }).catch((err) => {
            console.error({ err })
            window.VMA.showError({ title: 'Error al guardar' })
            reject(err)
          }).finally(() => {
            window.VMA.loading(false)
          })
        }
      }]
    })
    dialog.open()
    dialog.append(form)
  })
}

const update = ({
  schema, item = {}, model, title, preSave, url
}) => {
  const _item = JSON.stringify(item)
  const params = {
    dialogTitle: window.VMA.$vuetify.lang.t(`$vuetify.${model}.update`),
    url: `${url || ''}/${model}/${item.id}`,
    method: 'put',
  }
  return new Promise((resolve, reject) => {
    const form = new Form({
      schema,
      item
    })
    const dialog = new Dialog({
      title: params.dialogTitle,
      subtitle: title,
      actions: [{
        help: 'Guardar',
        icon: 'mdi-content-save',
        color: 'secondary',
        action: async () => {
          if (form.hasErrors()) {
            return
          }
          window.VMA.loading(true)
          const newItem = preSave ? await preSave(form.getItem()) : form.getItem()
          const oldItem = JSON.parse(_item)
          const set = {
            id: oldItem.id
          }
          Object.keys(newItem).forEach((key) => {
            set[key] = newItem[key]
          })
          ApiService({
            url: params.url,
            method: params.method,
            data: set,
            transformRequest: [transformRequest],
          }).then((resp) => {
            dialog.close()
            window.VMA.showMessage({
              title: `${window.VMA.$vuetify.lang.t(`$vuetify.${model}.name`)} actualizado`,
              // body: 'Uber Activado',
              color: 'success',
              center: false,
              right: true
            });
            resolve(resp)
          }).catch((err) => {
            console.error({ err })
            window.VMA.showError({ title: 'Error al guardar' })
            reject(err)
          }).finally(() => {
            window.VMA.loading(false)
          })
        }
      }]
    })
    dialog.open()
    dialog.append(form)
  })
}

const read = ({
  schema, item = {}, model, title
}) => {
  const form = new Form({
    schema,
    item,
    readonly: true
  })
  const dialog = new Dialog({
    title: window.VMA.$vuetify.lang.t(`$vuetify.${model}.read`),
    subtitle: title
  })
  dialog.open()
  dialog.append(form)
}

const _delete = ({
  id, model, title, url
}) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-restricted-globals, no-alert
  const hola = confirm(
    window.VMA.$vuetify.lang.t(`$vuetify.${model}.delete_confirm`) + '\n' + title
  )
  if (hola) {
    window.VMA.loading()
    ApiService({
      url: `${url || ''}/${model}/${id}`,
      method: 'delete',
    }).then((resp) => {
      resolve(resp)
    }).catch((err) => {
      console.error({ err })
      window.VMA.showError({ title: 'Error al eliminar' })
      reject(err)
    }).finally(() => {
      window.VMA.loading(false)
    })
  }
})

export default {
  create,
  read,
  update,
  delete: _delete
}
