<template>
  <div>
    <v-skeleton-loader v-if="loading" class="mx-auto" max-width="300" type="card"></v-skeleton-loader>
    <div v-else class="dashboard-padding">
      <v-row justify="center">
        <v-col cols="12" lg="2" md="6" sm="6">
          <cameras-card :items="camera_items" :data="cameras">
          </cameras-card>
        </v-col>
        <v-col cols="12" lg="2" md="6" sm="6">
          <bus-card :items="bus_items" :data="bus">
          </bus-card>
        </v-col>
        <v-col cols="12" lg="2" md="6" sm="6">
          <lpr-card :items="lpr_items" :data="lpr">
          </lpr-card>
        </v-col>
        <v-col cols="12" lg="2" md="6" sm="6">
          <sensor-card :items="sensor_items" :data="sensor">
          </sensor-card>
        </v-col>
        <v-col cols="12" lg="2" md="6" sm="6">
          <devices-card :items="device_items" :data="devices">
          </devices-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <apexchart type="line" height="450" :options="incidentsChart.chartOptions" :series="incidentsChart.chartData">
          </apexchart>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12">
          <base-material-card color="orange" class="px-2 py-3">
            <template v-slot:heading>
              <div class="card-title font-weight-light">
                {{ incidents.total }} Incidentes activos
              </div>
            </template>
            <v-card-text>
              <v-data-table :headers="incidentsTable.headers" :items="incident_items" :items-per-page="5">
                <template v-slot:item.uniqueId="{ item }">
                  <v-menu :close-on-content-click="false" :nudge-width="200" open-on-hover max-width="500px" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ item.uniqueId }}</span>
                    </template>
                    <v-card>
                      <v-card-title>
                        {{ item.uniqueId }}
                      </v-card-title>
                      <v-card-subtitle>
                        {{ item.attributes.fecha }} <br>
                        {{ item.attributes.motivo }}
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-card-text>
                        {{ item.attributes.descripcion }}
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
            <!-- <template v-slot:actions>
              Incidentes activos
            </template> -->
          </base-material-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ApiService from '@/util/api.service'
import CamerasCard from '@/components/dashboard/CamerasCard.vue'
import BusCard from '@/components/dashboard/BusCard.vue'
import LPRCard from '@/components/dashboard/LPRCard.vue'
import DevicesCard from '@/components/dashboard/DeviceCard.vue'
import SensorCard from '@/components/dashboard/SensorCard.vue'

export default {
  name: 'Home',
  components: {
    CamerasCard,
    BusCard,
    'lpr-card': LPRCard,
    SensorCard,
    DevicesCard,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      cameras_tab: null,
      loading: true,
      camera_items: [],
      bus_items: [],
      lpr_items: [],
      device_items: [],
      incident_items: [],
      sensor_items: [],
      incidents_chart: {
        days: [],
        medico: [],
        seguridad: [],
        proteccion_civil: [],
        servicios_publicos: []
      },
      cameras: {
        total: null,
        active: null,
        inactive: null
      },
      bus: {
        total: null,
        active: null,
        inactive: null
      },
      lpr: {
        total: null,
        active: null,
        inactive: null
      },
      devices: {
        total: null,
        active: null,
        inactive: null
      },
      sensors: {
        total: null,
        active: null,
        inactive: null
      },
      incidents: {
        total: null,
        active: null,
        inactive: null
      },
      incidentsTable: {
        headers: [
          {
            sortable: false,
            text: 'Folio',
            value: 'uniqueId',
          },
          {
            sortable: false,
            text: 'Incidente',
            value: 'attributes.motivo',
          },
          {
            sortable: true,
            text: 'Fecha',
            value: 'attributes.fecha',
            align: 'right',
          },
        ],
      },
    }
  },
  computed: {
    incidentsChart() {
      return {
        chartData: [
          {
            name: 'Seguridad Publica',
            data: this.incidents_chart.seguridad
          },
          {
            name: 'Emergencias Medicas',
            data: this.incidents_chart.medico
          },
          {
            name: 'Servicios Públicos',
            data: this.incidents_chart.servicios_publicos
          },
          {
            name: 'Protección Civil',
            data: this.incidents_chart.proteccion_civil
          }
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: 'line',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false
            }
          },
          colors: ['#0088a4', '#638a86', '#6f57a5', '#c17d19'],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '16px',
            }
          },
          stroke: {
            curve: 'smooth'
          },
          title: {
            text: 'Incidentes',
            align: 'center',
            style: {
              fontSize: '18px'
            }
          },
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          markers: {
            size: 1
          },
          xaxis: {
            categories: this.incidents_chart.days,
            title: {
              text: 'Dia',
              style: {
                fontSize: '14px'
              }
            }
          },
          yaxis: {
            title: {
              text: 'Incidentes registrados',
              style: {
                fontSize: '14px'
              }
            },
            labels: {
              style: {
                fontSize: '14px'
              }
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        }
      }
    }
  },
  mounted() {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const pathname = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);
    const connect = () => {
      if (this?.ws?.readyState === WebSocket.OPEN) {
        this.ws.send('ping');
      }

      if (!this.ws) {
        this.ws = new WebSocket(protocol + '//' + window.location.host + pathname + 'api/socket');
      } else if (this.ws.readyState === WebSocket.CLOSED || this.ws.readyState === WebSocket.CLOSING) {
        this.ws = new WebSocket(protocol + '//' + window.location.host + pathname + 'api/socket');
      } else {
        // console.log('Socket is already open');
        return;
      }
      console.log('Socket connecting...');
      // this.ws = new WebSocket(protocol + '//' + window.location.host + pathname + 'api/socket');
      this.ws.onmessage = (e) => {
        const _data = JSON.parse(e.data)
        if (_data.cameras) {
          this.update(_data.cameras, 'camera_items')
        }
        if (_data.buses) {
          this.update(_data.buses, 'bus_items')
        }
        if (_data.lprs) {
          this.update(_data.lprs, 'lpr_items')
        }
        if (_data.devices) {
          this.update(_data.devices, 'device_items')
        }
        if (_data.incidents) {
          this.update(_data.incidents, 'incident_items')
        }
        if (_data.sensors) {
          this.update(_data.sensors, 'sensor_items')
        }
      };
      this.ws.onerror = (err) => {
        console.error('Socket encountered error: ', { err });
        // window.VMA.showError({ title: 'Socket Error, Reconectand WebSocketo...', body: err.message })
        if (this.ws) {
          this.ws.close();
        }
        this.ws = null
      };
    }

    this.load().then(connect)
    this.timerSocket = setInterval(() => {
      connect();
    }, 3000);
  },
  unmouted() {
    clearInterval(this.timerSocket);
    if (this.ws) {
      this.ws.close();
    }

    this.ws = null;
  },
  destroyed() {
    clearInterval(this.timerSocket);
    if (this.ws) {
      this.ws.close()
    }
    this.ws = null
  },
  methods: {
    load(groupId) {
      return new Promise((res, rej) => {
        const camerasPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/cameras',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
        })
        const busesPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/bus',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
        })
        const lprPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/v2/analytic-devices',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
        })
        const sensorsPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/sensor',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
        })
        const devicesPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/devices',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
          }).catch((err) => {
            reject(err)
          })
        })
        const incidentsPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/incidents',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            resolve(resp)
            this.bus_items = resp
          }).catch((err) => {
            reject(err)
          })
        })
        const incidentsSummaryWeekPromise = new Promise((resolve, reject) => {
          ApiService({
            url: '/incidents/chart/categorysummary',
            method: 'get',
            params: groupId ? { groupId } : undefined
          }).then((resp) => {
            const ret = {}
            const proc = {
              days: [],
              medico: [],
              seguridad: [],
              proteccion_civil: [],
              servicios_publicos: []
            }
            resp.forEach((item) => {
              const fecha = item.created.substring(0, 10)
              if (!ret[fecha]) {
                ret[fecha] = {
                  medico: 0,
                  seguridad: 0,
                  servicios_publicos: 0,
                  proteccion_civil: 0
                }
              }
              ret[fecha][item.category] = item.accounting
            })
            Object.keys(ret).forEach((key) => {
              proc.days.push(key)
              proc.medico.push(ret[key].medico)
              proc.seguridad.push(ret[key].seguridad)
              proc.servicios_publicos.push(ret[key].servicios_publicos)
              proc.proteccion_civil.push(ret[key].proteccion_civil)
            })
            resolve(proc)
          }).catch((err) => {
            reject(err)
          })
        })
        Promise.all([
          camerasPromise,
          busesPromise,
          lprPromise,
          devicesPromise,
          incidentsPromise,
          sensorsPromise,
          incidentsSummaryWeekPromise
        ]).then(values => {
          this.camera_items = values[0]
          this.bus_items = values[1]
          this.lpr_items = values[2]
          this.device_items = values[3]
          this.incident_items = values[4]
          this.sensor_items = values[5]
          this.incidents_chart = values[6]
          this.loading = false
          res()
        }, reason => {
          rej(reason)
        });
      })
    },
    update(data, key) {
      data.forEach(item => {
        const index = this[key].findIndex((i) => i.id === item.id)
        if (index === -1) {
          this[key].push(item)
        } else {
          this.$set(this[key], index, item)
        }
      });
    },
  },
  watch: {
    camera_items(cameras) {
      this.cameras = {
        total: cameras.length,
        active: cameras.filter((a) => a.status === 'up').length,
        inactive: cameras.filter((a) => a.status === 'down').length
      }
    },
    bus_items(buses) {
      this.bus = {
        total: buses.length,
        active: buses.filter((a) => a.status === 'up').length,
        inactive: buses.filter((a) => a.status === 'down').length
      }
    },
    lpr_items(lprs) {
      console.log('lprs', lprs)
      this.lpr = {
        total: lprs.length,
        active: lprs.filter((a) => a.status === 'active' || a.status === 'partial').length,
        inactive: lprs.filter((a) => a.status !== 'active' || a.status !== 'partial').length
      }
    },
    sensor_items(sensors) {
      this.sensor = {
        total: sensors.length,
        active: sensors.filter((a) => a.status !== 'down').length,
        inactive: sensors.filter((a) => a.status === 'down').length
      }
    },
    device_items(devices) {
      this.devices = {
        total: devices.length,
        active: devices.filter((a) => a.status === 'online').length,
        inactive: devices.filter((a) => a.status === 'offline').length
      }
    },
    incident_items(incidents) {
      this.incidents = {
        total: incidents.length,
        active: incidents.filter((a) => a.status === 'up').length,
        inactive: incidents.filter((a) => a.status === 'down').length
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-padding {
  padding: 20px;
}

.card-title {
  font-size: 20px;
  font-weight: 400 !important;
}
</style>
